<template>
  <div class="contend dis-flex4" :style="{background:dataValue.backValue}">
    <div class="contend_box">
      <div v-if="dataValue.title" class="color333 fz40 fw600 center plan_title">快消品会员商城解决方案</div>
      <div v-if="dataValue.type !== 2" class="dis-flex2 dis-flex3">
        <div class="content_info_box">
          <div class="content_title fz36 fw600">{{dataValue.title_min}}</div>
          <div class="content_info fz16 color333">{{dataValue.dec}}</div>
          <div class="dis-flex">
            <div class="info_left color-fff fz16">咨询详情</div>
            <!-- <div class="info_right color-blue fz16" @click="urlJump">免费试用</div> -->
          </div>
        </div>
        <div class="content_img" :style="{width:dataValue.width,height:dataValue.height}"><img :style="{width:dataValue.width,height:dataValue.height}" :src="dataValue.url" alt=""></div>
      </div>
      <div v-else class="dis-flex2 dis-flex3">
        <div class="content_img" :style="{width:dataValue.width,height:dataValue.height}"><img :style="{width:dataValue.width,height:dataValue.height}" :src="dataValue.url" alt=""></div>
        <div class="content_info_box">
          <div class="content_title fz36 fw600">{{dataValue.title_min}}</div>
          <div class="content_info fz16 color333">{{dataValue.dec}}</div>
          <div class="dis-flex">
            <div class="info_left color-fff fz16">咨询详情</div>
            <!-- <div class="info_right color-blue fz16" @click="urlJump">免费试用</div> -->
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  name:"Planful",
  props:{
    dataValue:{
      type:Object,
      default:function(){
       return { 'backValue':'#F6FAFF',
        'paddingValue':'60px',
        'url':'tu_ylhuoke.png'}
      }
    }
  },
  setup(){
    const router = useRouter()
    const urlJump=()=>{
      router.push({ path: '/Login',query:{type:2}})
    }
    return {urlJump}
  }
}
</script>

<style lang="scss" scoped>
  .contend{
    .plan_title{
      padding-top:60px;
    }
    .contend_box{
      width:1200px;
      .content_info_box{
        width:465px;
        .content_info{
          padding:24px 0 50px 0;
        }
        .info_left{
          background:#0F6FFF;
          padding:13px 36px;
          border-radius: 4px;
          margin-right:20px;
          cursor: pointer;
        }
        .info_right{
          border:2px solid #0F6FFF;
          padding:13px 36px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
      .content_img{
        margin-right:60px;
        margin:60px 0;
      }
    }
  }
</style>