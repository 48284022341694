<template>
  <div class="content dis-flex4">
    <div class="content_box">
      <div class="content_title fz40 color333 fw600 center">行业痛点</div>
      <div class="dis-flex2">
        <template v-for="(item,index) in infos" :key="index">
          <Pointslist :infoList="item"></Pointslist>
        </template>
        
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import Pointslist from '@/components/Pointslist'
export default {
 name:"Points",
 components:{Pointslist},
 setup(){
   const info=[
    {urls:require('@/assets/img/icon_huoke.png'),title:'获客越来越难',dec:'传统电商平台营销成本高，缺乏主动流量，缺少和买家的互动及触点。'},
    {urls:require('@/assets/img/icon_liucun.png'),title:'客户难以留存',dec:'新客到店无法建立有效联系，沦为一次性流量。缺乏有效会员营销方式，难以刺激客户二次消费。'},
    {urls:require('@/assets/img/icon_yingxiao.png'),title:'营销触达低下',dec:'传统营销触达效率低，短信不足1%，电话接通率不足20%。'},
    {urls:require('@/assets/img/icon_dianpu.png'),title:'店铺管理难做',dec:'消费者用户数据落在平台，数据与商家无缘热销商品备货不及时，营销活动效果不清楚。'},
   ]
   const infos=reactive(info)
   return {infos}
 }
}
</script>

<style lang="scss" scoped>
  .content_box{
    padding:80px 0;
    width:1200px;
    .content_title{
      padding-bottom:60px;
    }
  }
</style>