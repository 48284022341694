<template>
  <div class="content">
    <div class="dis-flex4 content_img-box"><img class="content_img" :src="infoList.urls" alt=""></div>
    <div class="center color333 fw600 fz18">{{infoList.title}}</div>
    <div class="content_info fz14 color666">{{infoList.dec}}</div>
  </div>
</template>

<script>
export default {
  name:'Pointslist',
  props:{
    infoList:{
      type:Object,
      default:function(){

      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .content{
    box-shadow: 0px 0px 10px 1px #DDD;
    height:386px;
    border-radius: 16px;
    width:285px;
    .content_img-box{
      padding:60px 0 44px 0;
    }
    .content_img{
      width:108px;
      height:108px;
    }
    .content_info{
      padding:30px;
    }
  }
</style>