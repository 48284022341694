<template>
  <div class="contents dis-flex4 pos-r">
    <img class="content_img" src="https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/bg_banner_a.png" alt="">
    <div class="content_box_btn pos-r">
      <div class="box_btn dis-flex"><div class="content_btn color-blue fz20">咨询详情</div>
      <!-- <div class="content_btn1 color-fff fz20" @click="tryBtn">免费试用</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  name:'Bgimg',
  setup(){
    const router = useRouter()
    const tryBtn=()=>{
      router.push({ path: '/Login',query:{type:2}})
    }
    return {tryBtn}
  }
}
</script>

<style lang="scss" scoped>
  .contents{
    width:100%;
    height:600px;
    background-size:100% 100%;
    .content_img{
      position: absolute;
      width: auto;
      max-width: 100%;
      height: auto;
      min-height: 100%;
      vertical-align: middle;
    }
    .content_box_btn{
      width:1200px;
      height:100%;
      .box_btn{
        position: absolute;
        bottom:133px;
        left:0;
        .content_btn{
          padding:11px 30px;
          background:#fff;
          border-radius:4px ;
          margin-right:20px;
          cursor: pointer;
        }
        .content_btn1{
          border:2px solid #fff;
          padding:11px 30px;
          border-radius:4px ;
          cursor: pointer;
        }
      }
    }
  }
</style>