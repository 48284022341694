<template>
  <div id="nav" class="pos-r">
     <!-- <Navigation :tapInfos="tapInfos" /> -->
     <Bgimg></Bgimg>
  </div>
  <Points></Points>
  <template v-for="(item,index) in fluData" :key="index">
    <Planful  :dataValue="item"></Planful>
  </template>
  <!-- <Search></Search>
  <Footers></Footers> -->
  <!-- <Bottom></Bottom> -->
</template>
<script>
// @ is an alias to /src
// import Navigation from '@/components/Navigation'
import Bgimg from '@/components/Bgimg'
import Points from '@/components/Points'
import Planful from '@/components/Planful'
// import Search from '@/components/Search'
// import Footers from '@/components/Footers'
// import Bottom from '@/components/Bottom'

export default {
  name: 'Home',
  components: {
    // Navigation,
    Bgimg,
    Points,
    Planful,
    // Search,
    // Footers,
    // Bottom,
  },
  setup(){
    const fluData=[
      {'backValue':'#F6FAFF ',type:1,'paddingValue':'60px',url:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/tu_ylhuoke.png',title_min:'引流获客',dec:'支付宝首页、消费券会场、会员频道、生活号、卡包、搜索栏、模板消息、支付成功页、安心充会场、大促活动会场 ...',"title":'快消品会员商城解决方案','width':'510px','height':'390px'},
      {'backValue':'#fff ',type:2,'paddingValue':'60px',url:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/tu_shangc.png',title_min:'在线商城',dec:'借助支付宝流量红利，搭建属于自己的支付宝小程序电商平台。打通线上销售渠道，线下为线上引流。','width':'592px','height':'385px'},
      {'backValue':'#F6FAFF ',type:1,'paddingValue':'41px',url:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/tu_daodianzt.png',title_min:'到店自提',dec:'吸引顾客线上下单然后到店自提拉动线下门店的客流转化率以及客单价，实现线上与线下的相互促进。','width':'464px','height':'480px'},
      {'backValue':'#fff ',type:2,'paddingValue':'52px',url:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/tu_shangchaofl.png',title_min:'商超福利',dec:'通过附近商超发券吸引用户入会，引导用户到店核销，沉淀私域会员。','width':'440px','height':'377px'},
      {'backValue':'#F6FAFF ',type:1,'paddingValue':'41px',url:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/tu_huiyuanyy.png',title_min:'会员运营',dec:'Scrm会员全周期管理，将公域平台流量转化为商家私域流量，精细化运营提高复购。','width':'532px','height':'435px'},
      {'backValue':'#fff ',type:2,'paddingValue':'52px',url:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/tu_tuiguangfy.png',title_min:'推广分佣',dec:'推广员功能实现推广佣金奖励，让人人都是商家分销员，全面满足商家营销需求。','width':'490px','height':'385px'},
      {'backValue':'#F6FAFF ',type:1,'paddingValue':'60px',url:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/tu_zhinengyx.png',title_min:'智能营销',dec:'优惠券、专题活动、搭配特惠、入会有礼、生日有礼、精准发券、安心充、周期购、积分商城、每日签到、积分抽奖...','width':'432px','height':'400px'},
      {'backValue':'#fff ',type:2,'paddingValue':'41px',url:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/tu_shujufx.png',title_min:'数据分析',dec:'多角度、全方位的数据统计分析，经营数据可视化，数据驱动营销决策，促进业绩增长。','width':'590px','height':'442px'},
      ]
    return {fluData}
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
